import * as d3 from 'd3';
import { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MessagesContext } from '../../contexts/MessagesContext';
import { useCurrentUser } from '../../../../contexts/CurrentUser';
import { ampli } from '../../../../ampli';
import { API, ShareAPI } from '../../../../API';
import { Loader } from '../../../../components/Loader/Loader';
import { LoaderSmall } from '../../../../components/LoaderSmall/LoaderSmall';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { format, parseISO, formatISO, set } from 'date-fns';
import { ReactComponent as CalendarIcon } from '../../../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../../../assets/views.svg';
import { ReactComponent as EngagementIcon } from '../../../../assets/engagement.svg';
import { ReactComponent as ManipulationIcon } from '../../../../assets/manipulation.svg';
import { ReactComponent as TranslationIcon } from '../../../../assets/translation.svg';
import { ReactComponent as OriginalTextIcon } from '../../../../assets/originalText.svg';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete.svg';
import { Sentiment } from '../../../../components/Sentiment/Sentiment';
import { ExpandableText } from '../../../../components/CollapsibleText/ExpandableText';
import messageStyles from '../../../../components/MessagesTable/ExtendedMessageView.module.scss';
import styles from '../../NarrativePage.module.scss';
import Switch from '../../../../components/Switch';
import { TextEditor } from '../../../../components/TextEditor/TextEditor';
import Markdown from 'react-markdown';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');

export const Stories = ({
  narrative,
  searchQuery,
  start_date,
  end_date,
  statuses,
  sorting = '-engagement_sum',
  page = 1,
  size = 10,
  isShare,
  notes,
  settings,
  onSettingsChange,
  onWidgetStatusChange,
}) => {
  const {
    deduplication,
    dispatch,
    error,
  } = useContext(MessagesContext);
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const [stories, setStories] = useState(null);
  const [widgetSettings, setWidgetSettings] = useState(settings);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [note, setNote] = useState(notes?.length > 0 ? notes[0] : {text: "", position: "bottom"});
  const [isNote, setIsNote] = useState(notes?.length > 0);
  const [title, setTitle] = useState(widgetSettings.title || t('Top stories'));
  const componentRef = useRef(null);

  useEffect(() => {
    setWidgetSettings(settings);
    let newTitle = settings?.title?.length > 0 ? settings.title : t('Top stories');
    setTitle(newTitle);
  }, [settings]);


  const featureFlags = currentUser?.workspace?.config.featureflags;
  const showNer = featureFlags?.includes('ner_widget');
  const showMessageManipulationIndex = featureFlags?.includes(
    'show_manipulation_index',
  );
  const nativeLang = window.clientInformation.language.split('-', 1);

  const abortControllerRef = useRef();

  const fetchStories = (
    narrative,
    searchQuery,
    start_date,
    end_date,
    statuses,
    sorting,
    page,
    size = 100,
  ) => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', size);
    urlParams.set('page', page);

    if (searchQuery) {
      urlParams.set('q', searchQuery);
    }

    if (start_date) {
      urlParams.set('start_date', format(start_date, 'yyyy-LL-dd 00:00:00'));
    }

    if (end_date) {
      urlParams.set('end_date', format(end_date, 'yyyy-LL-dd 00:00:00'));
    }

    if (statuses) {
      statuses.forEach((status) => urlParams.append('message_status', status));
    }

    if (sorting) {
      if (Array.isArray(sorting.fieldName)) {
        sorting.fieldName.forEach((element) => {
          urlParams.append(
            'sorting',
            `${sorting.isAscending ? '' : '-'}${element}`,
          );
        });
      } else {
        urlParams.set('sorting', sorting);
      }
    }

    // Check if there's an existing request and abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;

    API.fetch(
      'GET',
      `/API/v1/narrative_stories/${narrative.id}?${urlParams.toString()}`,
      null,
      null,
      signal,
    )
      .then((data) => {
        setStories(data);
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          return;
        }
        dispatch({ type: 'ERROR', error: error.message });
      });
  };

  useEffect(() => {
    fetchStories(
      narrative,
      searchQuery,
      start_date,
      end_date,
      statuses,
      sorting,
      page,
      size,
    );
  }, [
    narrative,
    searchQuery,
    start_date,
    end_date,
    statuses,
    sorting,
    page,
    size,
  ]);

  const translateMessage = (messageId, text, nativeLang, sourceLang = '') => {
    let body;
    if (sourceLang) {
      body = {
        text: text,
        destination_language: nativeLang,
        source_language: sourceLang,
      };
    } else {
      body = {
        text: text,
        destination_language: nativeLang,
      };
    }
    API.fetch('POST', '/API/v1/translations/translate', null, body)
      .then((data) => {
        setStories({
          ...stories,
          objects: stories.objects.map((message) => {
            if (message.id === messageId) {
              message.isTranslation = true;
              message.translated = data.destination_text;
              return message;
            } else {
              return message;
            }
          }),
        });
      })
      .catch((e) => {
        setStories({
          ...stories,
          objects: stories.objects.map((message) => {
            if (message.id === messageId) {
              message.isTranslation = true;
              message.error = true;
              message.translated = t(
                'We were not able to translate this text.',
              );
              return message;
            } else {
              return message;
            }
          }),
        });
      });
  };

  const toggleTranslate = (messageId, isTranslation) => {
    setStories({
      ...stories,
      objects: stories.objects.map((message) => {
        if (message.id === messageId) {
          message.isTranslation = isTranslation;
          return message;
        } else {
          return message;
        }
      }),
    });
  };

  const handleTranslation = (messageId, text, isTranslation) => {
    const target = stories.objects.find((message) => message.id === messageId);
    if (target.translated) {
      toggleTranslate(messageId, isTranslation);
    } else {
      toggleTranslate(messageId, true);
      translateMessage(messageId, text, nativeLang[0].toUpperCase());
    }
  };

  const nerTypesList = [{ value: 'KEYWORDS', label: t('Matched keywords') }];

  if (showNer) {
    nerTypesList.push(
      { value: 'PERSON', label: t('Persons') },
      { value: 'ORGANIZATION', label: t('Organizations') },
      { value: 'LOCATION', label: t('Locations') },
    );
  }

  const [highlights, setHighlights] = useState(['KEYWORDS']);

  const statusMapping = {
    NEW: { class: styles.new, name: 'New' },
    READ: { class: styles.approved, name: 'Approved' },
    DELETED: { class: styles.deleted, name: 'Deleted' },
  };

  if (!narrative) {
    return <Loader />;
  }

  if (isEditingMode) {
    return (
      <div ref={componentRef} className="report-cection">
        <div className="widget-settings-container">
          <div className="widget-settings-title">{t('Top stories')}</div>
          <form
            onSubmit={() => {
              let newNotes;
              setIsEditingMode(false);
              if(note.text.length > 0) {
                newNotes = [note];
              } else {
                newNotes = [];
              }
              onSettingsChange(widgetSettings, newNotes, componentRef.current);
            }}
            onReset={() => {
              componentRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
              setTitle(settings.title || t('Top stories'));
              setIsEditingMode(false);
              setWidgetSettings(settings);
              setNote(notes?.length > 0 ? notes[0] : {text: "", position: "bottom"});
            }}
          >
            <div className="form-element">
              <label htmlFor="title">{t('Title')}</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setWidgetSettings({
                    ...widgetSettings,
                    title: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-element">
              <label htmlFor="full-text" className={styles.label}>
                {t('Show full text')}
              </label>
              <Switch
                id="full-text"
                value={widgetSettings.show_full_text}
                onChange={(value) => {
                  setWidgetSettings({
                    ...widgetSettings,
                    show_full_text: value,
                  });
                }}
              />
            </div>
            <div className="form-element">
              <label htmlFor="text-length">{t('Text length(characters)')}</label>
              <input
                type="number"
                id="text-length"
                value={widgetSettings.text_length}
                disabled={widgetSettings.show_full_text}
                onChange={(e) => {
                  setWidgetSettings({
                    ...widgetSettings,
                    text_length: parseInt(e.target.value),
                  });
                }}
              />
            </div>
            {stories.objects.length > 0 ? <div className="form-element">
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="isNote"
                    checked={isNote}
                    onChange={() => {
                      setIsNote(!isNote);
                    }}
                  />
                  <label htmlFor="create-note">{t('Create a note')}</label>
                </li>
              </ul>
            </div> : ''}
            {isNote ? <div className="form-element">
              <div className="form-element">
                <ul>
                  <li>
                    <input
                      type="checkbox"
                      id="platforms"
                      checked={note?.position === 'top'}
                      onChange={() => {
                        if(note?.position === 'top') {
                          setNote({...note, position: 'bottom'});
                        }
                        else {
                          setNote({...note, position: 'top'});
                        }
                      }}
                    />
                    <label htmlFor="note-position">{t('Place a note on the top of the widget')}</label>
                  </li>
                </ul>
              </div>

              <label htmlFor="note">{t('Note')}</label>
              <TextEditor
                id={"note-editor"}
                value={note.text}
                onChange={(text) => setNote({...note, text: text})}
                isOpen={true}
              />
            </div> : ''}
            <div className="widget-settings-controls">
              <button type="submit" className="new-button">
                {t('Apply')}
              </button>
              <button type="reset" className="btn-reset">
                {t('Cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={componentRef} className="report-section widget-container">
        <div className="report-section__header">
          <h3>
            {widgetSettings.title ? widgetSettings.title : t('Top stories')}
          </h3>
          <div className="report-section__controls">
            <button
              className="report-control-button"
              onClick={() => setIsEditingMode(true)}
            >
              <EditIcon />
            </button>
            <button
              className="report-control-button danger"
              onClick={() => onWidgetStatusChange(false)}
            >
              <DeleteIcon />
            </button>
          </div>
        </div>

        {error ? (
          <div className={styles.noData}>{t(error)}</div>
        ) : !stories ? (
          <Loader />
        ) : stories?.objects?.length > 0 ? (
          <>
          {notes && notes.length > 0
            ? notes.map((note) => {
                return (
                  <>
                    {note.position === 'top' ? (
                      <div className="report-note-container note-position-top">
                        <Markdown>{note.text}</Markdown>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                );
              })
            : ''}
          <table className={messageStyles.extendedMessages}>
            <tbody>
              {stories?.objects.map((message) => {
                let followers_cnt = message?.source?.audience;
                let textClass = messageStyles.extendedMessage;

                if (message.error && message.isTranslation) {
                  textClass = `${messageStyles.extendedMessage} ${messageStyles.error}`;
                } else if (message.error && !message.isTranslation) {
                  textClass = messageStyles.extendedMessage;
                }

                return (
                  <tr key={message.id}>
                    <td>
                      <div className={messageStyles.messageInfoWrapper}>
                        <div className={messageStyles.messageInfo}>
                          <div
                            className={`${messageStyles.status} ${
                              statusMapping[message.status].class
                            }`}
                          >
                            {t(statusMapping[message.status].name)}
                          </div>
                          <div className={messageStyles.date}>
                            <span className={messageStyles.anchor}>
                              <Tooltip
                                content={t('Publication date')}
                                position="bottom"
                              >
                                <CalendarIcon />
                              </Tooltip>
                            </span>
                            {format(
                              parseISO(message?.first_date + 'Z'),
                              'dd LLL yyyy HH:mm',
                            )}
                          </div>
                        </div>
                        <div
                          className={
                            'open-message-popup ' + messageStyles.modalIcon
                          }
                        >
                          {deduplication &&
                          message.deduplicated_messages !== null ? (
                            <div className={messageStyles.totalDeduplicated}>
                              Total messages:{' '}
                              <span
                                className={messageStyles.totalDeduplicatedValue}
                              >
                                {formatNumber(message.messages_count)}
                              </span>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>

                      <div className={messageStyles.messageContainer}>
                        <div className={messageStyles.messageMetricsWrapper}>
                          <div className={messageStyles.messageMetrics}>
                            {message.sentiment_score !== null ? (
                              <Sentiment
                                sentimentScore={message.sentiment_score}
                                messageId={message.id}
                                narrative={narrative}
                                isShare={isShare}
                                isStories={true}
                              />
                            ) : (
                              ''
                            )}
                            <span className={messageStyles.anchor}>
                              <Tooltip content={t('Views')} position="bottom">
                                <ViewsIcon />
                              </Tooltip>
                              {formatNumberSignificant(message.impressions_sum)}
                            </span>
                            <span className={messageStyles.anchor}>
                              <Tooltip
                                content={t('Reactions')}
                                position="bottom"
                              >
                                <EngagementIcon />
                              </Tooltip>
                              {formatNumberSignificant(message.engagement_sum)}
                            </span>
                            {showMessageManipulationIndex ? (
                              <span className={messageStyles.anchor}>
                                <Tooltip
                                  content={t('Manipulation')}
                                  position="bottom"
                                >
                                  <ManipulationIcon />
                                </Tooltip>

                                {message.manipulation_index_avg
                                  ? message.manipulation_index_avg.toFixed(2)
                                  : '0'}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>

                        <div className={messageStyles.textBlock}>
                          {message.isTranslation === true &&
                          !message.translated ? (
                            <div>
                              <LoaderSmall />
                            </div>
                          ) : (
                            <ExpandableText
                              length={settings?.text_length}
                              highlights={highlights}
                              showFullText={settings?.show_full_text}
                              text={
                                message.isTranslation
                                  ? message.translated
                                  : message.highlighted_summary ||
                                    message.summary
                              }
                              textClassName={textClass}
                              onExpand={(expanded) => {
                                ampli.track({
                                  event_type: expanded
                                    ? 'Expand story message in-place'
                                    : 'Collapse story message in-place',
                                  event_properties: {
                                    user_id: currentUser?.id,
                                    workspace_id: currentUser?.workspace_id,
                                    narrative_id: narrative.id,
                                  },
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="message-controlls">
                      <div className={messageStyles.translationWrapper}>
                        <span
                          className={
                            message?.isTranslation ? '' : messageStyles.active
                          }
                          onClick={() =>
                            handleTranslation(
                              message.id,
                              message.summary,
                              false,
                            )
                          }
                        >
                          <OriginalTextIcon />
                        </span>
                        <span
                          className={
                            message?.isTranslation ? messageStyles.active : ''
                          }
                          onClick={() =>
                            handleTranslation(message.id, message.summary, true)
                          }
                        >
                          <TranslationIcon />
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {notes && notes.length > 0
            ? notes.map((note) => {
                return (
                  <>
                    {note.position === 'bottom' ? (
                      <div className="report-note-container note-position-bottom">
                        <Markdown>{note.text}</Markdown>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                );
              })
            : ''}
          </>
        ) : (
          <div className={messageStyles.noData}>
            {t('There is no data to display')}
          </div>
        )}
      </div>
    );
  }
};
